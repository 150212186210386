import { render, staticRenderFns } from "./Desloratadine-game.vue?vue&type=template&id=421ce644&scoped=true"
import script from "./Desloratadine-game.vue?vue&type=script&lang=js"
export * from "./Desloratadine-game.vue?vue&type=script&lang=js"
import style0 from "./Desloratadine-game.vue?vue&type=style&index=0&id=421ce644&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421ce644",
  null
  
)

export default component.exports